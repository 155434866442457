.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 3px;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.btn-blue {
  color: #fff!important;
  border-bottom: 1px solid #1869d3;
  background: #1a73e8;
  box-shadow: 3px 4px 15px -8px #276d9a;
  &:hover {
    color: #fff!important;
    text-decoration: none;
    transition: .3s;
    background: #1963c6;
    box-shadow: 0 10px 19px -12px #022134;
  }
  &:active {
    background: #1653ad;
    box-shadow: 0 4px 9px -5px #004a79;
  }
}

.btn-orange {
  color: #fff!important;
  border-bottom: 1px solid #dfa72b;
  background: #ffc136;
  box-shadow: 3px 4px 15px -8px #956d14;
  &:hover {
    color: #fff!important;
    text-decoration: none;
    transition: .3s;
    background: #ffc136;
    box-shadow: 0 10px 19px -12px #956d14;
  }
  &:active {
    background: #eab32b;
    box-shadow: 0 4px 9px -5px #956d14;
  }
}

.btn-black {
  color: #fff!important;
  border-bottom: 1px solid #333;
  background: #333;
  box-shadow: 3px 4px 15px -8px #333;
  &:hover {
    color: #fff!important;
    text-decoration: none;
    transition: .3s;
    background: #111;
    box-shadow: 0 10px 19px -12px #111;
  }
  &:active {
    background: #000;
    box-shadow: 0 4px 9px -5px #000;
  }
}
