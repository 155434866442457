@import '../mixins/mixins.scss';

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  @include opacity(0);
  transition: transform .5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  @include opacity(.2);
  transition: 0s;
}


.fade-bottom {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image : linear-gradient(to bottom,
            rgba(255,255,255, 0),
            rgba(241,241,241, 1) 100%);
    width: 100%;
    height: 50%;
  }
}