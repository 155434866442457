
  @import '../assets/scss/core';
  @import '../assets/scss/contact';

  section.contact {
    background: #ffffff;
    @include media(lg) {
      background: #f1f1f1;
    }
  }
