//imported into core.scss

@import 'config';
@import 'responsive';
@import 'fonts';
@import 'helpers';
@import 'utilities';


body {
  font-size: $base__font-size;
  line-height: ($base__line/$base__font-size);
  font-weight: 300;
  font-family: $font-family--primary;
  color: $base__color;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family--secondary;
  font-weight: 400;
  color: $grey;
}
h1 {
  font-size: rem(48px);
  line-height: 1.3;
}

h3 {
  font-size: rem(36px);
  line-height: 1.2;
}


.img-responsive {
  max-width:100%;
  height:auto
}

.push-right {
  float: right;
}

.bg-white {
  background: $white;
}

.bg-grey {
  background: $bg-grey;
}

// Ripple effect

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.center {
  text-align: center!important;
}