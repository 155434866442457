@import 'navbar';

header {
  background: #000;
  .navbar {
    background: #000;
    padding: 8px 15px;
    .navbar-nav {
      align-items: center;
      padding: 10px 0 18px;
      background: #111;
      margin: 15px 0;
      @include media(lg) {
        background: unset;
        margin: unset;
        padding: 5px 0;
      }
    }
    .nav-link {
      font-weight: 500;
      color: #fff;
      padding-left: 12px!important;
      padding-right: 12px!important;
      &:last-child {
        color: #ffffff;
        background: #1a73e8;
        border-radius: 25px;
        padding: 10px 25px!important;
        margin-left: 10px;
        margin-top: 10px;
        @include media(lg) {
          margin-top: 0;
        }
      }
    }
  }
}