// Imported into layout.scss

@import '../mixins/mixins.scss';

.navbar {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  padding-top: rem(5px);
  padding-bottom: rem(5px);
  @include media(sm) {
    padding-top: rem(8px);
    padding-bottom: rem(8px);
  }
  @include media(md) {
    padding-top: rem(15px);
    padding-bottom: rem(15px);
  }
  @include media(lg) {
    padding-top: rem(25px);
    padding-bottom: rem(25px);
  }
  &-brand {
    img {
      width: 100px;
      height: 30px;
      @media(lg) {
        width: 120px;
      }
    }
  }
  .navbar-toggler {
    padding-right: 0;
    &:focus {
      outline: none;
    }
    .navbar-toggler-line {
      display: block;
      background: #fff;
      width: 28px;
      height: 3px;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}