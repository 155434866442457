@import '../mixins/mixins.scss';
footer {
  font-size: 16px;
  background: #000;
  .footer-content {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    flex-wrap: wrap;
    @include media(lg) {
      padding: 80px 0;
    }
  }
  .footer-links {
    width: 49%;
    max-width: 170px;
    font-size: 14px;
    list-style-type: none;
    color: #fff;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 30px;
    @include media(lg) {
      white-space: nowrap;
      margin-bottom: 0;
      width: unset;
      font-size: 16px;
    }
    &:first-child {
      order: 0;
      @include media(lg) {
        display: block;
      }
    }
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: 3;
      margin-bottom: 0;
      @include media(lg) {
        order: 2;
      }
    }
    &:last-child {
      margin-bottom: 0;
      order: 2;
      @include media(lg) {
        order: 3;
      }
    }
    a {
      color: #fff;
    }
    .footer-title {
      font-weight: 500;
      margin-bottom: 5px;
      @include media(lg) {
        margin-bottom: 40px;
      }
    }
    li {
      margin-bottom: 10px;
      font-size: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      @include media(lg) {
        margin-bottom: 15px;
        font-size: 14px;
      }
    }
  }
  .separator {
    hr {
      background: #191919;
      &:first-child {
        margin: 0 40px 0;
      }
      &:last-child {
        margin: 4px 15px 0;
      }
    }
  }
  .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    flex-direction: column;
    @include media(lg) {
      flex-direction: row;
    }
    img {
      width: 60px;
    }
    p {
      color: #fff;
      font-size: 10px;
      margin-bottom: 0;
      margin-top: 10px;
      @include media(lg) {
        font-size: 14px;
        margin-top: 0;
      }
    }
  }
}


