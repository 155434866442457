@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin box-shadow($value: none) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}


@mixin brand-color($brand, $color) {
  &[data-brand="#{$brand}"] {
    background: $color;
  }
}


@mixin menu-icon($title, $img) {
  &[title="#{$title}"]:before {
    background-image: url(/img/menu/#{$img}.jpg);
  }
}

@mixin transform-rotate($value) {
  -moz-transform: rotate($value);
  -webkit-transform: rotate($value);
  -o-transform: rotate($value);
  -ms-transform: rotate($value);
  transform: rotate($value);
}

@mixin opacity($value) {
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}