// Media query mixins
@import '../base/_responsive';
@mixin media($size) {
  @if $size == mobile-only {
    @media (max-width: 599px) { @content };
  } @else if $size == xs {
    @media (min-width: #{$mobile-xs}) { @content };
  } @else if $size == sm {
    @media (min-width: #{$mobile-sm}) { @content };
  } @else if $size == md {
    @media (min-width: #{$mobile-md}) { @content };
  } @else if $size == lg {
    @media (min-width: #{$mobile-lg}) { @content };
  } @else if $size == tablet {
    @media (min-width: #{$tablet}) { @content };
  } @else if $size == d-md {
    @media (min-width: #{$desktop-md}) { @content };
  } @else if $size == d-lg {
    @media (min-width: #{$desktop-lg}) { @content };
  }
}