/*
 * Global Config
 * Include Font family, Font Weights, Base Colors, Color Usage, Text
 */


// Font Stacks
//$font-url--google         : 'https://fonts.googleapis.com/css?family=Open+Sans:300,400|Quicksand:500&display=swap';

$font-family--primary     : 'Quicksand', Helvetica, Arial, sans-serif;
$font-family--secondary   : 'Quicksand', Helvetica, Arial, sans-serif;


// Descriptive Base Colors

$white        : #fff;
$black        : #0b0b0b;
$light-grey   : #828286;
$grey         : #333;
$bg-grey      : #f8f8f8;

$fountain-blue    : #52bab3;
$emerald          : #5ece7f;
$sunglo           : #e67478;
$coral            : #ff784f;
$purple-majesty   : #9279c3;
$scooter          : #39add1;


// Color Usage

$color-primary    : $fountain-blue;
$color-secondary  : $scooter;
$color-accent     : $emerald;
$color-shadow     : rgba($black, .2);


// Text

$base__font-size  : 16px;
$base__line       : 24px;
$base__color      : $light-grey;

// Path to Assets

$img-path         : "../img";