body {
  background: #f1f1f1;
}

.contact-form {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 40px 50px;
  margin: 0 160px;
  border-radius: 5px;
  box-shadow: 1px 10px 15px 3px rgba(0, 0, 0, .08);
  h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
  .btn {
    margin-top: 20px;
    width: 300px;
    padding: 15px 5px;
    align-self: center;
    font-size: 20px;
  }
}